import { defineMessages } from 'react-intl';
import { isMatterTranslationsEnabled } from '../utils/intlUtils';

const intlMessages = defineMessages({
  newProjectHeader: {
    defaultMessage: 'Create a new project',
  },
  newProjectHeader_Matter: {
    defaultMessage: 'Create a new matter',
  },
  updateProjectHeader: {
    defaultMessage: 'Upload to existing project',
  },
  updateProjectHeader_Matter: {
    defaultMessage: 'Upload to existing matter',
  },
  goBack: {
    defaultMessage: 'Go back',
  },
});

export const messages = {
  ...intlMessages,
  get newProjectHeader() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.newProjectHeader_Matter;
    } else {
      return intlMessages.newProjectHeader;
    }
  },
  get updateProjectHeader() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.updateProjectHeader_Matter;
    } else {
      return intlMessages.updateProjectHeader;
    }
  },
};
