import {
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  Stack,
} from '@fluentui/react';
import { DocumentView } from '../documents';
import { useEmlStore } from '../documents/store';
import useFetch from 'use-http';
import { API_BASE_URL, APP_BASE_URL } from '../../config';
import { Link } from '@fluentui/react-components';
import JSZip from 'jszip';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

type UploadProps = {
  title: string;
  orgId: string | number;
  projectId: string | number;
  projectTitle: string;
  conversationId: string;
  files: DocumentView[];
  updateLoading: (enabled: boolean) => void;
};

function lpad(ix: number, padding: number): string {
  const zeroes = new Array(padding + 1).join('0');
  const prefix = ('' + zeroes + ix.toString()).slice(-padding);
  return prefix;
}

export const UploadProgress = (params: UploadProps) => {
  const { orgId, files, projectId, projectTitle } = params;
  const [errorMsg, setErrorMsg] = useState<string>();
  const [importedDone, setImportedDone] = useState<boolean>(false);
  const [supportingDone, setSupportingDone] = useState<boolean>(false);

  const projectLink =
    APP_BASE_URL() + `/projects/${projectId}?organization=${orgId}`;

  const importURL =
    API_BASE_URL() +
    `/organizations/${orgId}/projects/${projectId}/documents/imports`;

  const {
    post: postImportedDocuments,
    response: importedDocumentsResponse,
    error: errorImportedDocuments,
  } = useFetch<Document[]>(importURL);

  const supportingDocsURL =
    API_BASE_URL() +
    `/organizations/${orgId}/projects/${projectId}/supporting-files`;

  const {
    post: postSupportingDocuments,
    response: supportingDocsResponse,
    error: errorSupportingDocuments,
  } = useFetch<Document[]>(supportingDocsURL);
  const { files: emlFiles } = useEmlStore();

  useEffect(() => {
    async function zipEMLFiles(emlFiles: File[]) {
      const files = [...emlFiles].reverse();
      const zip = new JSZip();
      files.forEach((file, index) => {
        const name = lpad(index + 1, 3) + '_' + file.name;
        zip.file(name, file);
      });

      const zipStream = await zip.generateAsync({ type: 'blob' });

      const mailItemSubject = Office.context?.mailbox.item?.subject
        ? Office.context?.mailbox.item?.normalizedSubject
        : 'EML files';

      return new File([zipStream], `${mailItemSubject}.zip`);
    }

    async function createFormdataImportedDocuments(documents: DocumentView[]) {
      const data = new FormData();
      const dataParts = await Promise.all(
        documents
          .filter(doc => doc.attachment)
          .map(document => {
            return document.content;
          }),
      );

      dataParts.forEach(part => {
        data.append('file', part, part.name);
      });
      return data;
    }

    async function createFormdataSupportingDocuments(emlFiles: File[]) {
      const data = new FormData();
      const part = await zipEMLFiles(emlFiles);
      data.append('file', part, part.name);
      return data;
    }

    const fetchData = async () => {
      const inputImportedDocs = await createFormdataImportedDocuments(files);
      if (inputImportedDocs.has('file')) {
        await postImportedDocuments(inputImportedDocs);
        if (errorImportedDocuments) {
          setErrorMsg(errorImportedDocuments.message);
        }
      } else {
        setImportedDone(true);
      }
      const inputSupportingDocs = await createFormdataSupportingDocuments(
        emlFiles,
      );
      if (inputSupportingDocs.has('file')) {
        await postSupportingDocuments(inputSupportingDocs);
        if (errorSupportingDocuments) {
          setErrorMsg(errorSupportingDocuments.message);
        }
      } else {
        setSupportingDone(true);
      }
    };

    if (projectId) {
      if (files?.length > 0) {
        void fetchData();
      } else {
        setImportedDone(true);
        setSupportingDone(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!importedDone) {
      setImportedDone(importedDocumentsResponse.status ? true : false);
    }
    if (!supportingDone) {
      setSupportingDone(supportingDocsResponse.status ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedDocumentsResponse.status, supportingDocsResponse.status]);

  useEffect(() => {
    if (!importedDone || !supportingDone) return;
    params.updateLoading(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedDone, supportingDone]);

  const intl = useIntl();

  return (
    <>
      {(!importedDone || !supportingDone) && (
        <Stack
          tokens={{ childrenGap: 20 }}
          style={{ marginTop: 20 }}
          verticalFill>
          <Stack.Item>
            <Spinner
              size={SpinnerSize.large}
              label={intl.formatMessage(messages.uploadingDocuments)}
            />
          </Stack.Item>
        </Stack>
      )}
      {importedDone && supportingDone && (
        <Stack tokens={{ childrenGap: 20 }} verticalFill>
          <Stack.Item>
            <p>{intl.formatMessage(messages.uploadToExistingProjectSuccess)}</p>
          </Stack.Item>
          <Stack.Item>
            <Link target="_blank" href={projectLink}>
              {intl.formatMessage(messages.openProject, {
                title: projectTitle,
              })}
            </Link>
          </Stack.Item>
        </Stack>
      )}
      {errorImportedDocuments && errorMsg && (
        <>
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            dismissButtonAriaLabel={intl.formatMessage(messages.close)}>
            <p>{intl.formatMessage(messages.failedToUploadDocuments)}</p>
            {errorMsg}
          </MessageBar>
        </>
      )}
    </>
  );
};
