import { FC } from 'react';
import homeMessages from './messages';
import pagesMessages from '../messages';
import { useIntl } from 'react-intl';
import { Stack, Link, DefaultButton, IButtonStyles } from '@fluentui/react';
import { useStateRouterStore } from '../../app/stateRouter/useStateRouterStore';
import { FeatureFlags, OrganizationSelect } from '../../features/organizations';
import { useOrganizationsStore } from '../../features/organizations/store';
import { config } from 'process';
import { APP_BASE_URL } from '../../config';

const buttonStyles: Partial<IButtonStyles> = {
  textContainer: {
    flexGrow: 0,
  },
};

export const Home: FC = () => {
  const {
    selectedOrganizationOption,
    setSelectedOrganizationOption,
    currentOrganizationSettings,
    organizationSettingsMeta,
  } = useOrganizationsStore();
  const intl = useIntl();
  const { setCurrentRoute } = useStateRouterStore();

  const organizations = useOrganizationsStore(state => state.organizations);

  const canUploadToExistingProject =
    currentOrganizationSettings?.[FeatureFlags.CAN_UPLOAD_DOCUMENTS];

  const showActionButtons = organizationSettingsMeta.success;

  const noActiveOrganization =
    organizationSettingsMeta.success &&
    organizations.length > 0 &&
    organizations.every(org => !(org.subscriptionStatus === 'active'));

  if (noActiveOrganization) {
    return (
      <Stack
        tokens={{ childrenGap: 20 }}
        style={{ marginTop: 20 }}
        verticalFill>
        <Stack.Item>
          <p>{intl.formatMessage(homeMessages.noActiveOrganizations)}</p>
          <Link
            target="_blank"
            href={`${APP_BASE_URL}/organization/integrations`}>
            {intl.formatMessage(homeMessages.noActiveOrganizationsLink)}
          </Link>
        </Stack.Item>
      </Stack>
    );
  }

  return (
    <>
      <p>
        {intl.formatMessage(homeMessages.homeDescription)}{' '}
        <Link
          target="_blank"
          href="https://help.precisely.se/en/precisely-microsoft-outlook-e-mail-integration">
          {intl.formatMessage(pagesMessages.learnMore)}
        </Link>
      </p>
      <Stack
        tokens={{
          childrenGap: 16,
        }}>
        <Stack.Item>
          <OrganizationSelect
            onChange={(_, organization) => {
              setSelectedOrganizationOption(organization.key);
            }}
            selected={selectedOrganizationOption}
          />
        </Stack.Item>
        {showActionButtons && (
          <>
            <DefaultButton
              data-testid="home.create-new-project.button"
              iconProps={{ iconName: 'PageAdd' }}
              styles={buttonStyles}
              onClick={() => {
                setCurrentRoute('/projects/new');
              }}>
              {intl.formatMessage(homeMessages.buttonCreateNewProject)}
            </DefaultButton>
            {canUploadToExistingProject && (
              <DefaultButton
                data-testid="home.update-project.button"
                iconProps={{ iconName: 'PageCheckedin' }}
                styles={buttonStyles}
                onClick={() => {
                  setCurrentRoute('/projects/update');
                }}>
                {intl.formatMessage(homeMessages.buttonUploadToExistingProject)}
              </DefaultButton>
            )}
          </>
        )}
      </Stack>
    </>
  );
};
