import { defineMessages } from 'react-intl';
import { isMatterTranslationsEnabled } from '../utils/intlUtils';

const intlMessages = defineMessages({
  connecting: { defaultMessage: 'Connecting to Precisely...' },
  startBySelectingTemplate: {
    defaultMessage:
      'To draft contracts in Precisely, start by selecting a template to create a project. Then select files to upload to the project.',
  },
  startBySelectingTemplate_Matter: {
    defaultMessage:
      'To draft contracts in Precisely, start by selecting a template to create a matter. Then select files to upload to the matter.',
  },
  learnMore: {
    defaultMessage: 'Learn more about Precisely + Outlook integration',
  },
  openAnEmailToStart: {
    defaultMessage:
      'To draft contracts using the Precisely add-in, open an email to begin.',
  },
  createProject: { defaultMessage: 'Create project in Precisely' },
  createProject_Matter: { defaultMessage: 'Create matter in Precisely' },
  reset: { defaultMessage: 'Reset' },
  creatingProject: { defaultMessage: 'Creating project' },
  creatingProject_Matter: { defaultMessage: 'Creating matter' },
  grantAccess: { defaultMessage: 'Grant access' },
  emailLabel: { defaultMessage: 'Email' },
  passwordLabel: { defaultMessage: 'Password' },
  loginButton: { defaultMessage: 'Login' },
  failedToLogin: { defaultMessage: 'Failed to login' },
  requestAccess: { defaultMessage: 'Request for access to Precisely' },
  appAccessInfo: {
    defaultMessage: 'Once connected this app will have access to Precisely',
  },
  showPassword: { defaultMessage: 'Show password' },
  loadingAttachments: { defaultMessage: 'Loading attachments' },
  close: { defaultMessage: 'Close' },
});

const messages = {
  ...intlMessages,
  get startBySelectingTemplate() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.startBySelectingTemplate_Matter;
    } else {
      return intlMessages.startBySelectingTemplate;
    }
  },
  get createProject() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.createProject_Matter;
    } else {
      return intlMessages.createProject;
    }
  },
  get creatingProject() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.creatingProject_Matter;
    } else {
      return intlMessages.creatingProject;
    }
  },
};

export default messages;
