import { defineMessages } from 'react-intl';
import { isMatterTranslationsEnabled } from '../../utils/intlUtils';

const intlMessages = defineMessages({
  selectDocuments: { defaultMessage: 'Select documents' },
  invalidFileType: { defaultMessage: 'invalid filetype' },
  emlFileLink: {
    defaultMessage:
      'Upload this email thread as EML files under “Supporting files” in the project',
  },
  emlFileLink_Matter: {
    defaultMessage:
      'Upload this email thread as EML files under “Supporting files” in the matter',
  },
  uploadEmailAsZipEml: {
    defaultMessage: 'Upload this email thread as zipped EML files',
  },
});

const messages = {
  ...intlMessages,
  get emlFileLink() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.emlFileLink_Matter;
    } else {
      return intlMessages.emlFileLink;
    }
  },
};

export default messages;
