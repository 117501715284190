import { defineMessages } from 'react-intl';
import { isMatterTranslationsEnabled } from '../../utils/intlUtils';

const intlMessages = defineMessages({
  homeDescription: {
    defaultMessage:
      "Through Precisely's integration with Outlook, you can create new matters, upload files to existing matters, or directly upload your files to the Precisely Archive.",
  },
  buttonCreateNewProject: {
    defaultMessage: 'Create new project',
  },
  buttonCreateNewProject_Matter: {
    defaultMessage: 'Create new matter',
  },
  buttonUploadToExistingProject: {
    defaultMessage: 'Upload to existing project',
  },
  buttonUploadToExistingProject_Matter: {
    defaultMessage: 'Upload to existing matter',
  },
  buttonUploadToArchive: {
    defaultMessage: 'Upload to archive',
  },
  loadingOrganizations: {
    defaultMessage: 'Loading organizations',
  },
  noActiveOrganizations: {
    defaultMessage:
      'No active organizations, review your license in the billing page: ',
  },
  noActiveOrganizationsLink: {
    defaultMessage: 'Billing page',
  },
});

const messages = {
  ...intlMessages,
  get buttonCreateNewProject() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.buttonCreateNewProject_Matter;
    } else {
      return intlMessages.buttonCreateNewProject;
    }
  },
  get buttonUploadToExistingProject() {
    if (isMatterTranslationsEnabled) {
      return intlMessages.buttonUploadToExistingProject_Matter;
    } else {
      return intlMessages.buttonUploadToExistingProject;
    }
  },
};

export default messages;
